@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .page-number  {
      @apply text-gray-500 cursor-pointer py-3 px-4 rounded-md font-semibold border hover:bg-red-500 hover:text-white outline-none 
  }
}

@layer utilities{
    .text-gray-custom{
        color:#838383;
    }
    .border-gray-custom{
        border-color:rgb(229, 231, 235)
    }
}

@media screen and (max-width: 1680px) {
  html {
     zoom: .9;   
  }
}

.border-color-primary{
    border-color:var(--primary);
}

.dash-border{
    border: 2px dashed #E4E8F6;
}

.menu-sidebar{
    grid-template-rows: auto 1fr auto;
}
.highcharts-credits{
    display: none;
}
.grid\:auto-1fr-auto-auto{
    grid-template-columns: auto 1fr auto auto;
}
.p-3\/5{
    padding: 3px 5px;
}
.active-chip{
    background-color:#CA5D99;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: transparent;
}
.shadow-1{
    box-shadow: 0px 100px 80px rgba(41, 72, 152, 0.05), 0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963), 0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704), 0px 20px 13px rgba(41, 72, 152, 0.025), 0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296), 0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
}
.shadow-1\/2{
    box-shadow: 0px 45.4805px 30.0871px rgba(41, 72, 152, 0.0303704)
}
.custom-select-option {
    padding: 6px 10px;
    font-size: 14px;
    color: #464646;
    cursor: pointer;
}
.custom-select-option-value{
    font-weight: 600;
    color:#111111;
    font-size:14px!important;
    padding-left: 2px;
    padding-right: 2px; 
}
.custom-select-option__control {
    border-radius: 20px!important;
    background: #f7f8fc!important;
    border: 1px solid #d3d4d5!important;
    box-shadow: none!important;
}
.custom-select-option__value-container {
    display: flex!important;
}
.custom-select-option__input {
  color: transparent;
}
#portal-root {
    position: relative;
    z-index: 998;
}
/* .record-with-form-wrapper .form{
    display: none;
    overflow: hidden;
}

.record-with-form-wrapper.show-form .record-list{
    display: none;
}
.record-with-form-wrapper.show-form .form{
    display: block;
} */

/* favourite item form area */
.checkbox-style-1 [type="checkbox"]:not(:checked),
.checkbox-style-1 [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
.checkbox-style-1 [type="checkbox"]:not(:checked) + label,
.checkbox-style-1 [type="checkbox"]:checked + label {
  position: relative;
  cursor: pointer;
  padding-left:20px;
  display: flex;
  align-items: center;
}

/* checkbox aspect */
.checkbox-style-1 [type="checkbox"]:not(:checked) + label:before,
.checkbox-style-1 [type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left:0; 
  width: 16px; 
  height: 16px;
  border: 1px solid #aaa;
  background: #f8f8f8;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.3)
}

/* .checkbox-style-1 [type="checkbox"]:not(:checked) + label:after,
.checkbox-style-1 [type="checkbox"]:checked + label:after {
  content: '✔';
  content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDQwNS4yNzIgNDA1LjI3MiI+Cgk8cGF0aCBkPSJNMzkzLjQwMSwxMjQuNDI1TDE3OS42MDMsMzM4LjIwOGMtMTUuODMyLDE1LjgzNS00MS41MTQsMTUuODM1LTU3LjM2MSwwTDExLjg3OCwyMjcuODM2ICAgYy0xNS44MzgtMTUuODM1LTE1LjgzOC00MS41MiwwLTU3LjM1OGMxNS44NDEtMTUuODQxLDQxLjUyMS0xNS44NDEsNTcuMzU1LTAuMDA2bDgxLjY5OCw4MS42OTlMMzM2LjAzNyw2Ny4wNjQgICBjMTUuODQxLTE1Ljg0MSw0MS41MjMtMTUuODI5LDU3LjM1OCwwQzQwOS4yMyw4Mi45MDIsNDA5LjIzLDEwOC41NzgsMzkzLjQwMSwxMjQuNDI1eiIgZmlsbD0iIzAwMDAwMCIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCI+PC9wYXRoPgo8L3N2Zz4=);
  left: 50%;
  margin-left:-25%;
  transition: all .2s;
  height:100%;
} */

.checkbox-style-1 [type="checkbox"]:not(:checked) + label:after,
.checkbox-style-1 [type="checkbox"]:checked + label:after {
    content: "";
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDQwNS4yNzIgNDA1LjI3MiI+Cgk8cGF0aCBkPSJNMzkzLjQwMSwxMjQuNDI1TDE3OS42MDMsMzM4LjIwOGMtMTUuODMyLDE1LjgzNS00MS41MTQsMTUuODM1LTU3LjM2MSwwTDExLjg3OCwyMjcuODM2ICAgYy0xNS44MzgtMTUuODM1LTE1LjgzOC00MS41MiwwLTU3LjM1OGMxNS44NDEtMTUuODQxLDQxLjUyMS0xNS44NDEsNTcuMzU1LTAuMDA2bDgxLjY5OCw4MS42OTlMMzM2LjAzNyw2Ny4wNjQgICBjMTUuODQxLTE1Ljg0MSw0MS41MjMtMTUuODI5LDU3LjM1OCwwQzQwOS4yMyw4Mi45MDIsNDA5LjIzLDEwOC41NzgsMzkzLjQwMSwxMjQuNDI1eiIgZmlsbD0iIzAwMDAwMCIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCI+PC9wYXRoPgo8L3N2Zz4=);
    position: absolute;
    left: 0;
    margin-left: 0;
    transition: all .2s;
    height: 16px;
    width: 16px;
    background-position: center center;
    background-repeat: no-repeat;
    scale: .75;
}
.checkbox-style-1 [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.checkbox-style-1 [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.checkbox-style-1 [type="checkbox"]:disabled:not(:checked) + label:before,
.checkbox-style-1 [type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
.checkbox-style-1 [type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
.checkbox-style-1 [type="checkbox"]:disabled + label {
  color: #aaa;
}

.checkbox-style-1 [type="checkbox"]:checked:focus + label:before,
.checkbox-style-1 [type="checkbox"]:not(:checked):focus + label:before {
  border: 1px dotted blue;
}
.checkbox-style-1 input[type=number]::-webkit-inner-spin-button, 
.checkbox-style-1 input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.tranx-table{
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

/* custom select style */
.custom-select__control {
  border: 0!important;
  /* margin: -0.5rem -0.75rem!important; */
  min-height: 2.7rem!important;
  background: transparent!important;
  padding: 0 0.75rem!important;
}

.custom-select__value-container {
  padding-left: 0!important;
}

.custom-select__placeholder {
  color: #2C2C2C!important;
  opacity: .40!important;
  font-size: 14px!important;
}
.custom-select__value-container {
  padding: 0!important;
}
.custom-select__indicator,
.custom-select__indicator-separator{
    display:none!important;
}
.custom-select__indicators:after{
    content:url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOCA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNOC4wMDA5OCAwLjVDOC4wMDA5OCAwLjIyNjU2MiA3Ljc3NDQxIC0xLjc4ODE0ZS0wNyA3LjUwMDk4IC0xLjc4ODE0ZS0wN0gwLjUwMDk3N0MwLjIyNzUzOSAtMS43ODgxNGUtMDcgMC4wMDA5NzY1NjIgMC4yMjY1NjIgMC4wMDA5NzY1NjIgMC41QzAuMDAwOTc2NTYyIDAuNjMyODEyIDAuMDU1NjY0MSAwLjc1NzgxMiAwLjE0OTQxNCAwLjg1MTU2MkwzLjY0OTQxIDQuMzUxNTZDMy43NDMxNiA0LjQ0NTMxIDMuODY4MTYgNC41IDQuMDAwOTggNC41QzQuMTMzNzkgNC41IDQuMjU4NzkgNC40NDUzMSA0LjM1MjU0IDQuMzUxNTZMNy44NTI1NCAwLjg1MTU2MkM3Ljk0NjI5IDAuNzU3ODEyIDguMDAwOTggMC42MzI4MTIgOC4wMDA5OCAwLjVaIiBmaWxsPSIjRDJEMkQyIi8+Cjwvc3ZnPg==")!important;
}
#headlessui-portal-root{
  z-index: 998;
  position: relative;
}

/* modal layout list style */
.modal-list{
  counter-reset: modal-counter;
  list-style: none;
  /* padding-left: 40px; */
}
.modal-list-item {
  margin: 0 0 0.5rem 0;
  counter-increment: modal-counter;
  position: relative;
}
.modal-list-item::before {
  display: none;
  content: counter(modal-counter);
  color: #D9241C;
  font-size: 1.5rem;
  font-weight: bold;
  position: absolute;
  --size: 32px;
  left: calc(-1 * var(--size) - 10px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  top: 0;
  /* transform: rotate(-10deg); */
  background: #FDF9EB;
  border-radius: 50%;
  text-align: center;
  box-shadow: 1px 1px 0 #999;
}

/* Quick notification area */
.quick-not-grid{
  overflow-x: auto;
  grid-template-columns: repeat(var(--total-grid), calc(25% - 20px));
  scroll-snap-type: x proximity;
  grid-gap: 20px;
  margin-left: -10px;
  padding-bottom: 10px;
}

/* .quick-not-grid:before,
.quick-not-grid:after {
  content: '';
} */

.slick-dots {
  transform: translateY(-0.5rem);
}
.PhoneInputInput:focus{
     outline: none;
}

/* slick */
.slick-prev,
.slick-next {
  width: 32px!important;
  height: 32px!important; 
  
}
.slick-next{
  right:-10px!important;
  background: rgb(0 0 0 / .25)  url('./assets/images/slick-next.svg') no-repeat center !important;
}
.slick-prev{
  left:-10px!important;
  background: rgb(0 0 0 / .25)  url('./assets/images/slick-prev.svg') no-repeat center !important;
  z-index: 1;
}
.slick-prev:hover, 
.slick-prev:focus, 
.slick-next:hover, 
.slick-next:focus{
  right:-10px!important;
  background-color: rgb(0 0 0 / .45)!important;
}
.slick-next,
.slick-prev{
  border-radius: 36px!important;
}
.slick-next:before,
.slick-prev:before{
  display:none;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
  background-color: #D9241C!important;
}
.react-datepicker {
  font-family: inherit!important;
}
.swal2-container {
  z-index: 9999!important;
}
#toast-root{
  z-index: 10000!important;
  position:relative;
}
.max-w-contentport{
  max-width: calc(100vw - 44rem);
}
.react-datepicker__portal {
  width: 100%!important;
  height: 100%!important;
}

.highcharts-container, .highcharts-container svg {
  width: 100% !important;
}
.react-datepicker__triangle::after,
.react-datepicker__triangle::before{
    left: -18px!important;
}

.highcharts-figure,
.highcharts-data-table table {
    min-width: 310px;
    max-width: 800px;
    margin: 1em auto;
}

#container {
    height: 400px;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

.scrollbar::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.table-scrollbar{
  max-height: 70vh;
  min-height: 20vh;
}
.highcharts-title{
  display: none!important;
}
.react-datepicker-ignore-onclickoutside{
  outline: none!important;
}
.react-datepicker__input-container input{
  /* width: 120px */
}
#print-preview .logo{
  width:180px;
  padding: 2px;
  border-radius: 6px;
  background-color: #f3f4f5;
}
.checked\:bg-primary:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(59 113 202/var(--tw-bg-opacity));
  background-color: var(--primary);
}

.checked\:border-primary:checked {
  --tw-border-opacity: 1;
  border-color: rgb(59 113 202/var(--tw-border-opacity));
  border-color: var(--primary);
} 

#print{
  width: 50vw;
}