.accordion__body.show-description {
  // margin-bottom: 15px;
  // height: 2rem;
}

/* Arrows */

/* closed */
.accordion__head-button::after {
  transition: transform 300ms ease-out;
}

/* open */
.font-weight-bold::after {
  transform: rotate(540deg);
}
